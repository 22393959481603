import wx from 'weixin-js-sdk'
import https from '../https'
export function shareUrl (daurl, title, desc, shareLogo) {
  https.get('tools/wxshare', {
    url: daurl
  }).then(res => {
    if (res.code === 0) {
      wx.config(res.data)
      wx.ready(function () {
        wx.hideMenuItems({
          menuList: [
            'menuItem:copyUrl',
            'menuItem:openWithSafari',
            'menuItem:openWithQQBrowser',
            'menuItem:originPage',
            'menuItem:share:qq',
            'menuItem:share:weiboApp',
            'menuItem:favorite',
            'menuItem:share:facebook',
            'menuItem:share:QZone'
          ]
        })
        wx.updateAppMessageShareData({
          title: title,
          desc: desc,
          link: res.data.url,
          imgUrl: shareLogo,
          success: function () {
            // doing somethings
          }
        })
        wx.updateTimelineShareData({
          title: title,
          link: res.data.url,
          imgUrl: shareLogo,
          success: function () {
            // doing somethings
          }
        })
      })
    }
  })
}
export function hideShare (daurl) {
  https.get('tools/wxshare', {
    url: daurl,
    hide: 1
  }).then(res => {
    if (res.code === 0) {
      wx.config(res.data)
      wx.ready(function () {
        wx.hideAllNonBaseMenuItem()
      })
    }
  })
}
